<template>
	<div class="about-us">
		<div class="context-container">
			<h2 class="context-title">About Our Team</h2>
			<div class="line"></div>
			<p v-for="(description, index) in aboutOurTeam" :key="index" class="context-desc">
				{{ description }}
			</p>

			<h2 class="context-title">Our Mission</h2>
			<div class="line"></div>
			<p class="context-desc">{{ ourMission }}</p>

			<h2 class="context-title">Our Vision</h2>
			<div class="line"></div>
			<p class="context-desc">{{ ourVision }}</p>

			<h2 class="context-title">Our Values</h2>
			<div class="line"></div>
			<p class="context-desc">{{ ourValues }}</p>


			<ul class="values-list">
				<li v-for="(value, index) in values" :key="index">
					<p class="context-desc"><strong>{{ value.valueTitle }}:</strong> {{ value.valueDesc }}</p>
				</li>
			</ul>

			<h2 class="context-title">What We Do</h2>
			<div class="line"></div>
			<p class="context-desc">{{ whatWeDo }}</p>

			<ul class="services-list">
				<li v-for="(service, index) in services" :key="index">
					<p class="context-desc"><strong>{{ service.serviceTitle }}:</strong> {{ service.serviceDesc }}</p>
				</li>
			</ul>

			<h2 class="context-title">Why We Do It</h2>
			<div class="line"></div>
			<p class="context-desc">{{ whyWeDoIt }}</p>

			<h2 class="context-title">Innovation</h2>
			<div class="line"></div>
			<p class="context-desc">{{ innovation }}</p>

			<h2 class="context-title">Community Playbook</h2>
			<div class="line"></div>
			<p class="context-desc">{{ communityPlaybook }}</p>

			<h2 class="context-title">Why We’re Different</h2>
			<div class="line"></div>
			<p class="context-desc">{{ whyWeAreDifferent }}</p>

			<h2 class="context-title">Join Us</h2>
			<div class="line"></div>
			<p class="context-desc">{{ joinUs }}</p>
		</div>
	</div>
</template>

<script>
import { getCompanyInformation } from "../../../services/company";
export default {
	data() {
		return {
			aboutOurTeam: [],
			ourMission: "",
			ourVision: "",
			ourValues: "",
			values: [],
			whatWeDo: "",
			services: [],
			whyWeDoIt: "",
			innovation: "",
			communityPlaybook: "",
			whyWeAreDifferent: "",
			joinUs: "",
		};
	},
	mounted() {
		const { aboutOurTeam, ourMission, ourVision, ourValues, Values, whatWeDo, services, whyWeDoIt, innovation, communityPlaybook, whyWeAreDifferent, joinUs  } = getCompanyInformation();
		this.aboutOurTeam = aboutOurTeam;
		this.ourMission = ourMission;
		this.ourVision = ourVision;
		this.ourValues = ourValues;
		this.values = Values;
		this.whatWeDo = whatWeDo,
		this.services = services;
		this.whyWeDoIt = whyWeDoIt;
		this.innovation = innovation;
		this.communityPlaybook = communityPlaybook;
		this.whyWeAreDifferent = whyWeAreDifferent;
		this.joinUs = joinUs;
	},

};
</script>

<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.about-us {
	background-color: #fff;
	font-family: 'Inter', sans-serif;
	font-weight: 400; 
	color: #040505;
}

.values-list,
.services-list {
	margin-left: 36px;
		p {
			padding-top: 0;
		}
}

@media (max-width: 768px) {

	.job-title-text {
		font-size: 1.25rem !important;
	}

	.sub-job-title-text {
		font-size: 1.2rem !important;
	}
}
</style>
